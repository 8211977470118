.footer {
    position: relative;
    color: #fff;
    padding: 50px 0;
    text-align: center;
  }
  
  .footer-gradient {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: black;
    z-index: -1;
  }
  
  .footer-content {
    max-width: 900px;
    margin: 0 auto;
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
  }
  
  .social-media {
    display: flex;
    justify-content: center;
    gap: 20px;
  }
  
  .social-icon {
    font-size: 2em;
    color: #fff;
    transition: color 0.3s;
  }
  
  .social-icon:hover {
    color: #00FF7F; /* Highlight color */
  }
  
  .contact-info p {
    margin: 0;
    color: #ddd;
    margin-top: 20px;
  }
  
  .contact-info a {
    color: #00FF7F; /* Email link color */
    text-decoration: none;
  }
  
  .contact-info a:hover {
    text-decoration: underline;
  }
  
  .footer-bottom {
    margin-top: 30px;
    border-top: 1px solid #2d3142;
    padding-top: 15px;
    font-size: 0.9em;
    color: #bbb;
  }
  
  @media (max-width: 768px) {
    .footer-content {
      padding: 0 20px;
    }
  }
  