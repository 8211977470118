/* General styles */
@import "~@fortawesome/fontawesome-free/css/all.css";

body, html {
    margin: 0;
    padding: 0;
    font-family: 'Roboto', sans-serif;
  }
  
  .app {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    width: 100%;
  }
  
  
  
  .app-main {
    padding: 100px 20px 60px;
    flex: 1;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .content-wrapper {
    max-width: 800px;
    text-align: center;
    z-index: 1;
  }
  
  .main-title {
    font-size: 2.5rem;
    margin-bottom: 20px;
    font-weight: 700;
    color: #fff;
  }
  
  .highlight {
    color: #32CD32;
    position: relative;
  }
  
  .highlight::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 4px;
    background-color: #32CD32;
    border-radius: 2px;
  }
  
  .cta-button {
    background-color: #32CD32;
    color: white;
    padding: 15px 30px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    font-size: 18px;
    margin-bottom: 40px;
    transition: background-color 0.3s ease;
  }
  
  .cta-button:hover {
    background-color: #28a745;
  }
  
  .features {
    display: flex;
    justify-content: space-around;
    margin-top: 40px;
  }
  
  .feature {
    text-align: center;
    max-width: 200px;
  }
  
  .feature-icon {
    font-size: 30px;
    margin-bottom: 10px;
    color: #32CD32;
  }
  
  .feature p {
    margin-top: 10px;
  }
  
  .background-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(135deg, rgba(0, 0, 0, 0.71), rgba(28, 28, 28, 0.5)), url('../images/stock.jpg') no-repeat center center/cover;
    z-index: 0;
  }
  
  .app-footer {
    text-align: center;
    padding: 20px;
    background-color: #333;
  }
  
  .app-footer p {
    margin: 0;
    font-size: 14px;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .app-header {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .app-nav {
      display: none;
      flex-direction: column;
      width: 100%;
    }
  
    .app-nav a {
      padding: 10px 20px;
      display: block;
    }
  
    .login-button {
      width: 100%;
      margin-top: 10px;
    }
  
    .app-header.open .app-nav {
      display: flex;
    }
  
    .features {
      flex-direction: column;
      align-items: center;
    }
  
    .feature {
      margin: 20px 0;
    }
  }
  
  /* Animations */
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  .app-main {
    animation: fadeIn 1s ease-in-out;
  }
  

/*Second section*/
.container {
  width: 100%;
  /* max-width: 1200px; */
  margin: 0 auto;
  /* padding: 20px; */
  text-align: center;
}

.section {
  margin-bottom: 40px;
}

.highlight {
  color: #4CAF50; /* Green color */
}

.content {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-top: 60px;
}

.chart {
  flex: 1;
  max-width: 450px;
  max-height: 450px;
  
}

.chart img {
  width: 100%;
  height: auto;
  border-radius: 10px;
}

.story-list {
  flex: 1;
  max-width: 400px;
  list-style: none;
  padding: 0;
  text-align: left;
}

.story-list li {
  font-size: 18px;
  margin-bottom: 30px;
}

@media (max-width: 768px) {
  .content {
    flex-direction: column;
  }

  .chart, .story-list {
    max-width: 100%;
  }
}













.success-stories-section {
  text-align: center;
  padding: 20px;
  background-color: #f9f9f9; /* Background color can be adjusted */
}

.success-stories-section h2 {
  font-size: 2em;
  margin-bottom: 20px;
}

.highlight {
  color: #00FF7F; /* Highlight color */
}

.stories-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 40px;
}

.story-card {
  background-color: #fff;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.story-image {
  width: 100%;
  height: 350px;
  background-size: cover;
  background-position: center;
}

.story-details {
  padding: 15px;
}

.story-details h3 {
  font-size: 1.2em;
  margin: 10px 0;
}

.story-details p {
  color: #555;
}

.cta-container {
  margin-top: 30px;
}

.cta-button {
  border: none;
  padding: 10px 20px;
  font-size: 1em;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.cta-button:hover {
  background-color: #00cc66;
}

@media (max-width: 768px) {
  .stories-container {
    flex-direction: column;
    align-items: center;
  }

  .story-card {
    width: 90%;
  }
}



/*fifth section*/
.fifth-section {
  padding: 50px 0;
  background-color: #fff; /* White background for the section */
  text-align: center;
}

.content-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  max-width: 1100px;
  margin: 0 auto;
  padding: 0 20px;
}

.content-card {
  background-color: #f9f9f9;
  border: 1px solid #e1e1e1;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.content-card:hover {
  transform: translateY(-5px);
}

.icon {
  font-size: 2em;
  color: #00FF7F; /* Green color for icons */
  margin-bottom: 15px;
}

h3 {
  font-size: 1.2em;
  margin: 15px 0;
}

p {
  font-size: 1em;
  color: #333;
}

.cta-button_fifth {
  margin-top: 30px;
}

.cta-button_fifth button {
  background-color: #00FF7F;
  border: none;
  border-radius: 5px;
  padding: 15px 30px;
  font-size: 1em;
  color: #fff;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.cta-button_fifth button:hover {
  background-color: #00cc66;
}
