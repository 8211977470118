.pricing-section {
    padding: 60px 20px;
    background-color: #f4f4f4;
    text-align: center;
    margin-top: 30px;
  }
  
  .pricing-section h2 {
    font-size: 2.5em;
    margin-bottom: 30px;
    color: #333;
  }
  
  .pricing-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    max-width: 1100px;
    margin: 0 auto;
  }
  
  .pricing-card {
    background-color: #fff;
    border: 1px solid #e1e1e1;
    border-radius: 8px;
    padding: 20px;
    width: 300px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .pricing-card h3 {
    font-size: 1.8em;
    margin-bottom: 15px;
    color: #333;
  }
  
  .pricing-card p {
    font-size: 1.2em;
    color: #666;
  }
  
  .pricing-card .price {
    font-size: 2em;
    color: #00FF7F;
    margin: 10px 0;
  }
  
  .pricing-card .billing {
    font-size: 1em;
    color: #333;
    margin-bottom: 20px;
  }
  
  .pricing-card ul {
    list-style: none;
    padding: 0;
    text-align: left;
  }
  
  .pricing-card li {
    font-size: 1em;
    color: #666;
    margin-bottom: 10px;
  }
  
  .pricing-card.featured {
    background-color: #00FF7F;
    color: #fff;
  }
  
  .pricing-card.featured h3,
  .pricing-card.featured .price,
  .pricing-card.featured p,
  .pricing-card.featured .billing {
    color: #fff;
  }
  
  .pricing-card.featured li {
    color: #f8f8f8;
  }
  
  .contact-info {
    margin-top: 30px;
  }
  
  .contact-info p {
    font-size: 1.2em;
    color: #333;
  }
  
  .contact-info a {
    color: #00FF7F; /* Green for links */
    text-decoration: none;
    transition: color 0.3s ease;
  }
  
  .contact-info a:hover {
    color: #008000; /* Darker green on hover */
  }
  