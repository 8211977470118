/* General styles for desktop and mobile */
.app-header {
  background: rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(10px);
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 100;
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 40px;
}



.app-logo {
  width: 120px; /* Default size for desktop */
  height: 100px; /* Maintain aspect ratio */
}

.app-logo img {
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.app-nav {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.app-nav a {
  text-decoration: none;
  color: inherit;
}

.app-nav li {
  color: white;
  margin: 0 15px;
  list-style: none;
  font-weight: 500;
}

.app-nav li:hover {
  color: #32CD32;
}

.hamburger-icon {
  display: none;
  font-size: 28px;
  color: white;
  cursor: pointer;
}

/* Side drawer styles */
.side-drawer {
  position: fixed;
  width: 250px;
  max-width: 80%;
  height: 100%;
  background: rgba(0, 0, 0, 0.9);
  box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.5);
  transform: translateX(-100%);
  transition: transform 0.3s ease-out;
  top: 0;
  left: 0;
  z-index: 200;
}

.side-drawer.open {
  transform: translateX(0);
}

.side-drawer .close-button {
  font-size: 30px;
  color: white;
  cursor: pointer;
  padding: 15px;
}

.side-drawer ul {
  height: 100%;
  list-style: none;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  padding: 1rem;
}

.side-drawer ul li {
  margin: 10px 0;
}

.side-drawer ul li a {
  color: white;
  text-decoration: none;
  font-size: 1.2em;
}

.side-drawer ul li:hover a {
  color: #32CD32;
}

.backdrop {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 150;
}

/* Media Queries for responsive design */
@media (max-width: 768px) {
  .app-nav {
    display: none;
  }

  .hamburger-icon {
    display: block;
  }
}
