.about-us {
    padding: 60px 0;
    background-color: #f4f4f4;
    text-align: center;
  }
  
  .about-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
  }
  
  .about-text {
    flex: 1;
    min-width: 300px;
    padding: 20px;
  }
  
  .about-text h2 {
    font-size: 2.5em;
    margin-bottom: 20px;
  }
  
  .about-text h3 {
    font-size: 1.8em;
    margin-bottom: 15px;
    color: #00FF7F; /* Green color for the subtitle */
  }
  
  .about-text p {
    font-size: 1.2em;
    color: #333;
    line-height: 1.5;
  }
  
  .about-image {
    flex: 1;
    min-width: 300px;
    padding: 20px;
  }
  
  .about-image img {
    margin-top: 5rem;
    max-width: 100%;
    height: auto;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  