.blog-page {
    padding: 40px;
    background-color: #f5f5f5;
    text-align: center;
    margin-top: 70px;
  }
  
  .blog-page-title {
    font-size: 32px;
    color: #333;
    margin-bottom: 40px;
  }
  
  .blog-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 40px;
  }
  
  .blog-item {
    background-color: white;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    text-align: left;
  }
  
  .blog-image {
    width: 100%;
    height: auto;
  }
  
  .blog-content {
    padding: 20px;
  }
  
  .blog-title {
    font-size: 24px;
    color: #333;
    margin-bottom: 15px;
  }
  
  .blog-description {
    font-size: 16px;
    color: #666;
    margin-bottom: 20px;
  }
  
  .read-more {
    background-color: #32CD32;
    border: none;
    color: white;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  
  .read-more:hover {
    background-color: #28a745;
  }
  
  @media (max-width: 768px) {
    .blog-list {
      flex-direction: column;
      align-items: center;
    }
  }
  