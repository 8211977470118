.certificates-container {
    padding: 40px;
    text-align: center;
    background-color: #f5f5f5;
    margin-top: 60px;
  }
  
  .certificates-heading {
    font-size: 35px;
    color: #333;
    margin-bottom: 20px;
  }
  
  .certificates-description {
    font-size: 16px;
    color: #555;
    margin-bottom: 40px;
  }
  
  .certificates-gallery {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
  }
  
  .certificate-card {
    flex: 1 1 calc(50% - 40px);
    max-width: calc(50% - 40px);
    border: 1px solid #ddd;
    border-radius: 10px;
    overflow: hidden;
    background-color: #fff;
  }
  
  .certificate-card img {
    width: 100%;
    height: auto;
  }
  
  @media (max-width: 768px) {
    .certificate-card {
      flex: 1 1 100%;
      max-width: 100%;
    }
  }
  