.student-success-section {
    text-align: center;
    padding: 40px 20px;
    background-color: #1f2331; /* Dark background color */
    color: #fff;
  }
  
  .student-success-section h2 {
    font-size: 2em;
    margin-bottom: 10px;
    color: #fff;
  }
  
  .highlight {
    color: #00FF7F; /* Highlight color */
  }
  
  .description {
    margin-bottom: 30px;
    color: #bbb;
  }
  
  .students-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
    justify-items: center;
  }
  
  .student-card {
    background-color: #2d3142;
    border-radius: 10px;
    overflow: hidden;
    width: 100%;
    max-width: 300px;
    text-align: center;
    transition: transform 0.3s;
  }
  
  .student-card:hover {
    transform: scale(1.05);
  }
  
  .video-container {
    width: 100%;
    padding-top: 56.25%; /* 16:9 Aspect Ratio */
    position: relative;
  }
  
  .video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  
  .student-details {
    padding: 15px;
  }
  
  .student-details h3 {
    margin: 10px 0;
    font-size: 1.2em;
  }
  
  .student-details p {
    color: #ccc;
    font-size: 0.9em;
  }
  
  @media (max-width: 768px) {
    .student-success-section h2 {
      font-size: 1.5em;
    }
  }
  