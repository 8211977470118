.landing-section {
    text-align: center;
    padding: 2rem;
  }
  
  .title {
    font-size: 2rem;
    margin-bottom: 1rem;
    color: #4CAF50;
    font-weight: bold;
  }
  
  .underline {
    color: #333;
    text-decoration: underline;
  }
  
  .card-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1rem;
  }
  
  .card {
    background: #f8faff;
    padding: 1.5rem;
    border-radius: 0.5rem;
    flex: 1;
    min-width: 250px;
    max-width: 300px;
    text-align: left;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  .card img {
    display: block;
    margin: 0 auto 1rem;
    width: 40px;
    height: 40px;
  }
  
  .card p {
    font-size: 1rem;
    color: #333;
  }
  
  .cta-button {
    background: #4CAF50;
    color: white;
    border: none;
    padding: 0.75rem 2rem;
    border-radius: 0.5rem;
    font-size: 1rem;
    margin-top: 2rem;
    cursor: pointer;
  }
  
  .cta-button:hover {
    background: #45a049;
  }
  